<template>
  <Dialog
    v-model:visible="modalLotes"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '80vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-th-large" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Asignación Lotes</strong>
        </h4>
      </div>
    </template>
    <div class="grid lg:grid-cols-10 xl:grid-cols-10 grid-cols-1 gap-2">
      <div class="col-span-6">
        <span class="font-bold text-xs">Cod. Medicamento: </span><br>
        <p class="text-xs">{{ dataMx.U_PHR_ItemCodSolicitado + ' - ' + dataMx.Dscription }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Conversión Original: </span>
        <p class="text-xs">{{ $h.formatNumber(dataMx.U_PHR_Conversion) }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Cant. Factura: </span>
        <p v-if="onQuantity >= 0" class="text-xs">{{ $h.formatNumber(onQuantity) }}</p>
        <p v-if="onQuantity < 0" class="text-xs"><small class="text-red-500">La cantidad de la factura no puede ser
          inferior a la sumatoria asignada en los lotes {{ $h.formatNumber(onQuantity) }}</small></p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Cant. Recibido: </span>
        <p class="text-xs">{{ $h.formatNumber(dataMx.receivedQuantity) }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Cant. Defectuosa: </span>
        <p class="text-xs">{{ $h.formatNumber(onQuantityDefecto) }}</p>
      </div>
    </div>
    <div class="grid lg:grid-cols-10 xl:grid-cols-10 grid-cols-1 gap-2" v-show="dataMx.U_PHR_ItemSustituto">
      <div class="col-span-6">
        <span class="font-bold text-xs">Cod. Medicamento Sustituto: </span><br>
        <p class="text-xs">{{ dataMx.ItemCode + ' - ' + dataMx.U_PHR_ItemSustituto }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Conversión sustituto: </span>
        <p class="text-xs">{{ dataMx.U_PHR_ConversionSustituto }}</p>
      </div>
    </div>
    <div class="gap-2 grid 2xl:grid-cols-4 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 pt-3">
      <div>
        <span class="font-bold text-xs sm:mr-2">Ubicaciones: </span><br>
        <Dropdown
          v-model="loteAgregado.AbsEntry"
          :options="arrayUbicaciones"
          class="rounded-md border-gray-300 text-xs w-full"
          optionLabel="SL1Code"
          :showClear="true"
          optionValue="AbsEntry"
          placeholder="Seleccione una ubicación..."
        />
        <br>
        <MessageError class="text-xs" :text="errors.AbsEntry"/>
      </div>
      <div>
        <span class="font-bold text-xs w-full">Lote: </span><br>
        <InputText @blur="onSearchBatchNumber(dataMx.ItemCode)" class="p-inputtext-xs w-full"
                   v-model="loteAgregado.lote"/>
        <br>
        <MessageError class="text-xs" :text="errors.lote"/>
      </div>
      <div>
        <span class="font-bold text-xs w-full">Cantidad: </span><br>
        <InputNumber inputId="integeronly" class="p-inputtext-xs w-full" v-model="loteAgregado.cantidad"/>
        <br>
        <MessageError class="text-xs" :text="errors.cantidad"/>
      </div>
      <div>
        <span class="font-bold text-xs">Fecha Venc.: </span>
        <br>
        <input :disabled="disableExpDate"
               style="padding: 0.30rem;"
               :min="minDate"
               :max="maxDate"
               class="p-button-secondary border rounded-md p-inputtext-xs w-full"
               v-model="loteAgregado.expDate"
               type="date">
        <br>
        <MessageError class="text-xs" :text="errors.expDate"/>
      </div>
    </div>
    <div class="pt-8">
      <DataTable
        :value="lotesMedicamento"
        class="p-datatable-sm text-xs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
      >
        <template #empty>
          Agregue un lote para asignar.
        </template>
        <template #header>
          <div class="flex justify-end align-items-center">
            <span class="p-input-icon-left">
                    <Button icon="pi pi-plus" :disabled="validateCant" label="Agregar Lote" @click="addLine"
                            class="p-button-outlined p-button-success p-button-sm"/>
                  </span>
          </div>
        </template>
        <Column field="" header="Ubicación" headerClass="lg:text-xs" headerStyle="width:30%"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <Dropdown
              v-model="data.AbsEntry"
              :options="arrayUbicaciones"
              class="rounded-xs border-gray-300 w-full text-xs"
              optionLabel="SL1Code"
              optionValue="AbsEntry"
              disabled
              placeholder="Seleccione una ubicacion..."
            />
          </template>
        </Column>
        <Column field="" header="Lote" headerClass="lg:text-xs" bodyStyle="text-align: center" headerStyle="width:25%"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ data.lote }}</p>
          </template>
        </Column>
        <Column field="" header="Cantidad" bodyStyle="text-align: center" headerClass="lg:text-xs"
                headerStyle="width:20%" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ $h.formatNumber(data.cantidad) }}</p>
          </template>
        </Column>
        <Column field="" header="Fecha Vencimiento" bodyStyle="text-align: center" headerClass="lg:text-xs"
                headerStyle="width:15%" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ data.expDate }}</p>
          </template>
        </Column>
        <Column field="" header="Acciones" bodyStyle="text-align: center" headerClass="lg:text-xs"
                headerStyle="width:10%" class="my-1 py-1 shadow-xs">
          <template #body="slotProps">
            <div class="flex gap-2 justify-center">
              <Button v-tooltip.top="'Editar'" @click="updateLines(slotProps)"
                      class="p-button-text p-button-help p-0">
                <editIcon class="w-4"/>
              </Button>
              <Button v-if="slotProps.data.alertaExpDate" v-tooltip.top="'Cargar carta compromiso'"
                      @click="uploadCarta(slotProps)" class="p-button-text p-button-info p-0">
                <corner-right-upIcon class="w-4"/>
              </Button>
              <Button v-if="slotProps.data.cartaPdf && slotProps.data.NombreCartaPdf" v-tooltip.top="'Eliminar carta'"
                      @click="removeCarta(slotProps)" class="p-button-text p-button-secondary p-0">
                <folder-minusIcon class="w-4"/>
              </Button>
              <Button v-tooltip.top="'Registrar'" @click="openModalNovedades(slotProps)"
                      class="p-button-text p-button-warning p-0">
                <alertTriangleIcon class="w-4"/>
              </Button>
              <Button v-tooltip.top="'Eliminar'" @click="deleteLines(slotProps)"
                      class="p-button-text p-button-danger p-0">
                <x-squareIcon class="w-4"/>
              </Button>
              <input type="file" ref="pdfCartaInput" class="hidden" accept="application/pdf" @change="handleChangePdf"/>
            </div>
            <small class="text-muted mt-2">{{ slotProps.data.NombreCartaPdf }}</small>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-center mt-2">
      <Button icon="pi pi-check-square" label="Confirmar" @click="confirmLine"
              class="p-button-outlined p-button-sm"/>
    </div>
    <modalNovedades ref="modalNovedades" :isEdit="false"/>
  </Dialog>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import recepcionPedidos from '@/apps/pharmasan/compras/logistica/store/recepcionPedidos'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import dayjs from 'dayjs'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { helper } from '@/utils/helper'

export default {
  name: 'modalAsignarLotes',
  components: {
    modalNovedades: defineAsyncComponent(() =>
      import('./../components/modalRegistrarNovedades.vue')
    )
  },
  setup () {
    const modalLotes = ref(false)
    const dataMx = ref({})
    const lotes = ref([])
    const minDate = ref('')
    const maxDate = ref('')
    const temporal = ref('')
    const modalNovedades = ref()
    const permisoCartaCompromiso = ref(false)
    const lineClose = ref(false)
    const disableExpDate = ref(false)
    const arrayUbicaciones = ref([])
    const valueDataTemp = ref({})
    const pdfCartaInput = ref()
    const schema = yup.object({
      // codBodega: yup.string().required('El campo es requerido').label('Código de bodega'),
      AbsEntry: yup.string().required('El campo es requerido').label('Código de bodega'),
      lote: yup.string('').required('El campo es requerido').label('Lote medicamento'),
      cantidad: yup.number().required('El campo es requerido').label('Cantidad medicamento'),
      novedades: yup.array(),
      expDate: yup.date().required('El campo es requerido').label('Fecha vencimiento medicamento'),
      cartaBase64: yup.string('').label('Variable temporal carta compromiso'),
      cartaNombre: yup.string('').label('Variable temporal carta compromiso')
    })
    useField('AbsEntry')
    useField('lote')
    useField('cantidad')
    useField('expDate')
    useField('novedades')
    useField('cartaBase64')
    useField('cartaNombre')
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const { errors, values: loteAgregado, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    // const onQuantity = computed(() => {
    //   const filter = lotesStore.value.filter((x) => x.ItemCode === dataMx.value.ItemCode && x.LineNum === dataMx.value.LineNum && x.BaseRef === dataMx.value.DocNum)
    //   const resultCantidad = filter.reduce((total, current) => {
    //       return total + current.cantidad
    //     }, 0)
    //   return dataMx.value.Quantity - resultCantidad
    // })
    const onQuantity = computed(() => {
      const filter = lotesStore.value.filter((x) => x.U_PHR_ItemCodSolicitado === dataMx.value.U_PHR_ItemCodSolicitado && x.LineNum === dataMx.value.LineNum && x.BaseRef === dataMx.value.DocNum && x.visible)
      const resultCantidad = filter.reduce((total, current) => {
        return total + current.cantidad
      }, 0)
      return dataMx.value.Quantity - resultCantidad
    })
    const onQuantityDefecto = computed(() => {
      const filter = lotesStore.value.filter((x) => x.U_PHR_ItemCodSolicitado === dataMx.value.U_PHR_ItemCodSolicitado && x.LineNum === dataMx.value.LineNum && x.BaseRef === dataMx.value.DocNum)
      let resultCantidad = 0
      if (filter.length) {
        // 877 absEntry de la ubicacion de devoluciones pedidos
        resultCantidad = filter.filter(a => a.AbsEntry === 877).reduce((total, current) => {
          return total + current.cantidad
        }, 0)
        //   if (resultCantidad === 0) resultCantidad = dataMx.value.cantidadDefectuosa
      }
      return resultCantidad < 0 ? 0 : dataMx.value.cantidadDefectuosa - resultCantidad
      // if (resultado < 0) return 0
      // return resultado
    })
    const validateCant = computed(() => {
      if (onQuantity.value === 0 && onQuantityDefecto.value === 0) {
        return true
      } else {
        return false
      }
    })
    const uploadCarta = async ({ data }) => {
      valueDataTemp.value = data
      pdfCartaInput.value.click()
    }
    const handleChangePdf = async () => {
      if (pdfCartaInput.value.files[0]) {
        const params = {
          file: await helper.base64String(pdfCartaInput.value.files[0]),
          data: valueDataTemp.value,
          fileName: pdfCartaInput.value.files[0].name
        }
        recepcionPedidos.commit('updateCartaConfirm', params)
      }
    }
    const removeCarta = ({ data }) => {
      recepcionPedidos.commit('removeCarta', data)
    }
    // const storeRecepcionPedidosBodegas = computed(() => recepcionPedidos.getters._bodegas)
    // const detailsLotes = computed(() => recepcionPedidos.getters._detailsLotes)
    const lotesStore = computed(() => recepcionPedidos.getters._lotes)
    const lotesMedicamento = computed(() => {
      return lotesStore.value.filter((x) => x.U_PHR_ItemCodSolicitado === dataMx.value.U_PHR_ItemCodSolicitado && x.LineNum === dataMx.value.LineNum && x.BaseRef === dataMx.value.DocNum && x.visible !== false)
    })
    const futureExpDate = (ExpDate) => {
      const date = new Date()
      const validateDays = date.setMonth(date.getMonth() + parseInt(process.env.VUE_APP_MESES_ALERTA_MEDICAMENTO_VENCIDO_RCP_PEDIDOS_COMPRAS)) // fecha actual + la sumatoria de días/meses dinámicamente, para validar que la fecha de vencimiento este en el rango
      if (ExpDate >= minDate.value && dayjs(validateDays).format('YYYY-MM-DD') >= ExpDate) {
        Swal.fire({
          icon: 'info',
          text: 'La fecha de vencimiento de este medicamento esta próxima a expirar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return true
      }
      return false
    }
    // const bodegas = ref([])
    const addLine = handleSubmit((values) => {
      // let validateTemp = 0
      if (!values) return
      if (lotesStore.value.length) {
        const findIndexLote = lotesStore.value.find((x) => `${x.cantidad}${x.AbsEntry}${x.expDate}${x.lote}` === temporal.value)
        if (findIndexLote) recepcionPedidos.commit('onDeleteLote', findIndexLote)
      }
      const infoExtraLote = arrayUbicaciones.value.find(a => a.AbsEntry === values.AbsEntry)
      // 39 SL1Abs de la ubicación de devoluciones pedidos
      if (dataMx.value.cantidadDefectuosa === 0 && infoExtraLote.SL1Abs === 39) {
        Swal.fire({
          icon: 'info',
          html: 'Esta línea no tiene cantidades defectuosas que puedan ser ingresadas a la ubicación de <strong>DEVOLUCIONES</strong>, por favor verificar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4500,
          timerProgressBar: true
        })
        return
      }
      const filter = lotesStore.value.filter((x) => x.U_PHR_ItemCodSolicitado === dataMx.value.U_PHR_ItemCodSolicitado && x.LineNum === dataMx.value.LineNum && x.BaseRef === dataMx.value.DocNum)
      let validarCantDefectuosa = 0
      if (filter.length) {
        // 39 SL1Abs de la ubicación de devoluciones pedidos
        validarCantDefectuosa = filter.filter(a => a.SL1Abs === 39).reduce((total, current) => {
          return total + current.cantidad
        }, 0)
      }
      if (infoExtraLote.SL1Abs === 39) {
        validarCantDefectuosa = validarCantDefectuosa + values.cantidad
      }
      if (validarCantDefectuosa > dataMx.value.cantidadDefectuosa) {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad defectuosa no puede ser mayor a la ingresada inicialmente.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if (values.lote.length >= 36) {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad de caracteres del lote es demasiado larga, por favor verificar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if (minDate.value >= loteAgregado.expDate && dataMx.value.cantidadDefectuosa === 0 && loteAgregado.AbsEntry === 876) {
        Swal.fire({
          icon: 'info',
          html: 'La fecha del lote ingresado ya expiró, si este medicamento posee lotes vencidos por favor ingrese las cantidades en <strong>Cantidad defectuosa</strong>. e intente asignar nuevamente',
          showConfirmButton: true
        })
        return
      }
      if (minDate.value >= loteAgregado.expDate && dataMx.value.cantidadDefectuosa > 0 && loteAgregado.AbsEntry === 876) {
        Swal.fire({
          icon: 'info',
          html: `No se pueden ingresar cantidades con lotes vencidos a la ubicación <strong>${infoExtraLote.SL1Code}</strong><br>Por favor revise.`,
          showConfirmButton: true
        })
        return
      }
      if (onQuantity.value >= loteAgregado.cantidad && loteAgregado.cantidad > 0) {
        const dataLoteMx = {
          lote: loteAgregado.lote.toUpperCase(),
          cantidad: loteAgregado.cantidad,
          expDate: loteAgregado.expDate,
          novedades: loteAgregado.novedades && loteAgregado.novedades.length ? loteAgregado.novedades.map((a) => {
            /* actualiza información de lote en las novedades */
            a.AbsEntry = loteAgregado.AbsEntry
            a.DistNumber = loteAgregado.lote.toUpperCase()
            a.ExpDate = loteAgregado.expDate
            a.Quantity = parseInt(loteAgregado.cantidad)
            a.SL1Abs = infoExtraLote.SL1Abs
            return a
          }) : [],
          U_PHR_MedAdicional: 0,
          ItemCode: [null, undefined].includes(dataMx.value.U_PHR_ItemSustituto) ? dataMx.value.U_PHR_ItemCodSolicitado : dataMx.value.ItemCode,
          ItemName: dataMx.value.Dscription,
          CodeBars: dataMx.value.CodeBars,
          U_PHR_ItemCodSolicitado: dataMx.value.U_PHR_ItemCodSolicitado,
          U_PHR_Quantity: dataMx.value.U_PHR_Quantity,
          UnitPrice: dataMx.value.U_PHR_UnitPriceSolicitado,
          U_PHR_UnitPriceSolicitado: dataMx.value.Price,
          WarehouseCode: 'BOD0002',
          U_PHR_WarehouseCodeOri: dataMx.value.WhsCode,
          medAdicional: dataMx.value.medAdicional,
          AbsEntry: loteAgregado.AbsEntry,
          SL1Abs: infoExtraLote.SL1Abs,
          DiscountPercent: 0,
          TaxCode: dataMx.value.TaxCode,
          BaseLine: dataMx.value.BaseLine,
          BaseEntry: dataMx.value.DocEntry,
          BaseRef: dataMx.value.DocNum,
          CantidadCompleta: null,
          receivedQuantity: dataMx.value.receivedQuantity,
          cantidadDefectuosa: dataMx.value.cantidadDefectuosa,
          temperatura: dataMx.value.temperatura,
          cadenaFrio: dataMx.value.cadenaFrio,
          LineNum: dataMx.value.LineNum,
          cartaPdf: loteAgregado.cartaBase64 ?? '',
          NombreCartaPdf: loteAgregado.cartaNombre ?? '',
          alertaExpDate: futureExpDate(loteAgregado.expDate),
          visible: true,
          indexLote: lotesStore.value.length
        }
        handleReset()
        recepcionPedidos.commit('onSetDataLotes', dataLoteMx)
      } else {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad debe ser mayor que 0 y no puede ser superior a la cantidad restante del pedido.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    })
    const updateLines = ({ data }) => {
      loteAgregado.cantidad = data.cantidad
      loteAgregado.lote = data.lote
      loteAgregado.expDate = data.expDate
      loteAgregado.AbsEntry = data.AbsEntry
      loteAgregado.novedades = data.novedades
      loteAgregado.cartaBase64 = data.cartaBase64
      loteAgregado.cartaNombre = data.NombreCartaPdf
      temporal.value = `${data.cantidad}${data.AbsEntry}${data.expDate}${data.lote}`
      recepcionPedidos.commit('onLoteEditable', data)
    }

    const deleteLines = ({ data }) => {
      recepcionPedidos.commit('onDeleteLote', data)
    }
    const openModalNovedades = ({ index, data }) => {
      const payload = {
        lote: {
          DistNumber: data.lote,
          Quantity: data.cantidad,
          ExpDate: data.expDate,
          AbsEntry: data.AbsEntry,
          SL1Abs: data.SL1Abs,
          novedades: data.novedades
        },
        medicamento: {
          labelLote: `Lote: ${data.lote} Cant.: ${data.cantidad} Fec. Venc.: ${data.expDate} Ubicación: ${data.SL1Abs === 39 ? 'DEVOLUCIONES' : 'PENDIENTESOV'}`,
          LineNum: dataMx.value.LineNum,
          DocNum: dataMx.value.DocNum,
          DocEntry: dataMx.value.DocEntry,
          ItemCode: dataMx.value.ItemCode,
          Dscription: dataMx.value.Dscription,
          nCompleto: dataMx.value.nCompleto,
          Quantity: dataMx.value.Quantity,
          receivedQuantity: dataMx.value.receivedQuantity,
          cantidadDefectuosa: dataMx.value.cantidadDefectuosa,
          temperatura: dataMx.value.temperatura,
          cadenaFrio: dataMx.value.cadenaFrio,
          U_PHR_ItemCodSolicitado: dataMx.value.U_PHR_ItemCodSolicitado,
          U_PHR_ItemSustituto: dataMx.value.U_PHR_ItemSustituto ?? dataMx.value.U_PHR_ItemSustituto,
          Price: dataMx.value.Price,
          CodeBars: dataMx.value.CodeBars
        }
      }
      modalNovedades.value.openModal(payload)
    }
    const onSearchBatchNumber = (ItemCode) => {
      if (loteAgregado.lote) {
        _RecepcionPedidosService.value.getBatchNumber(loteAgregado.lote, ItemCode).then(({ data }) => {
          if (data.length > 0) {
            futureExpDate(data[0].ExpDate)
            loteAgregado.expDate = data[0].ExpDate
            disableExpDate.value = true
          } else {
            disableExpDate.value = false
          }
        })
      }
    }
    const ubicaciones = () => {
      _RecepcionPedidosService.value.getUbicaciones().then(({ data }) => {
        arrayUbicaciones.value = data
      })
    }
    const confirmLine = () => {
      let validate = false
      lotesMedicamento.value.forEach((element) => {
        if (element.alertaExpDate && !permisoCartaCompromiso.value) {
          validate = true
          Swal.fire({
            icon: 'info',
            text: 'La carta de compromiso para el medicamento próximo a vencer es obligatoria, por favor revisar.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
      })
      if (!validate) {
        if (onQuantity.value === 0 && onQuantityDefecto.value === 0) {
          const lineasUnicas = [...new Set(lotesMedicamento.value.map((x) => `${x.LineNum}${x.U_PHR_ItemCodSolicitado}${x.BaseRef}${x.WarehouseCode}`))]
          for (const line of lineasUnicas) {
            const itemInfo = lotesMedicamento.value.filter((l) => `${l.LineNum}${l.U_PHR_ItemCodSolicitado}${l.BaseRef}${l.WarehouseCode}` === line)
            const payload = {
              U_PHR_MedAdicional: itemInfo[0].medAdicional,
              LineNum: itemInfo[0].LineNum,
              ItemCode: itemInfo[0].ItemCode,
              ItemName: itemInfo[0].ItemName,
              CodeBars: itemInfo[0].CodeBars,
              U_PHR_ItemCodSolicitado: itemInfo[0].U_PHR_ItemCodSolicitado,
              Quantity: itemInfo.reduce((total, current) => {
                return total + parseInt(current.cantidad)
              }, 0),
              U_PHR_Quantity: itemInfo[0].U_PHR_Quantity,
              UnitPrice: itemInfo[0].UnitPrice,
              U_PHR_UnitPriceSolicitado: itemInfo[0].U_PHR_UnitPriceSolicitado,
              WarehouseCode: itemInfo[0].WarehouseCode,
              U_PHR_WarehouseCodeOri: itemInfo[0].U_PHR_WarehouseCodeOri,
              DiscountPercent: itemInfo[0].DiscountPercent,
              TaxCode: itemInfo[0].TaxCode,
              BaseLine: itemInfo[0].BaseLine,
              BaseEntry: itemInfo[0].BaseEntry,
              // medAdicional: itemInfo[0].medAdicional,
              BaseRef: itemInfo[0].BaseRef,
              receivedQuantity: itemInfo[0].receivedQuantity,
              cantidadDefectuosa: itemInfo[0].cantidadDefectuosa,
              temperatura: itemInfo[0].temperatura,
              cadenaFrio: itemInfo[0].cadenaFrio,
              lotes: itemInfo.map((a) => {
                return {
                  DistNumber: a.lote,
                  ExpDate: a.expDate,
                  Quantity: parseInt(a.cantidad),
                  AbsEntry: a.AbsEntry,
                  SL1Abs: a.SL1Abs,
                  cartaPdf: a.cartaPdf,
                  novedades: a.novedades ? a.novedades : []
                }
              })
            }
            recepcionPedidos.dispatch('onSetDocumentLines', payload)
            recepcionPedidos.commit('onCloseLine', itemInfo[0])
          }
          // lotes.value = []
          modalLotes.value = false
        } else {
          Swal.fire({
            icon: 'info',
            text: 'Inconsistencias en las cantidades, por favor validar.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      }
    }
    const openModalLotes = ({ detalle, permisoFecha, permisoCarta }) => {
      if (lotesStore.value.length > 0) recepcionPedidos.commit('onResetRowEdit')
      handleReset()
      const date = new Date()
      minDate.value = dayjs(date).format('YYYY-MM-DD')
      const futureDate = new Date()
      futureDate.setFullYear(date.getFullYear() + 5)
      if (!permisoFecha) {
        maxDate.value = dayjs(futureDate).format('YYYY-MM-DD')
      }
      permisoCartaCompromiso.value = permisoCarta
      dataMx.value = detalle
      // loteAgregado.codBodega = detalle.U_PHR_WarehouseCodeOri ?? detalle.WhsCode
      // bodegas.value = storeRecepcionPedidosBodegas.value
      ubicaciones()
      modalLotes.value = true
    }
    return {
      modalLotes,
      openModalLotes,
      dataMx,
      lotes,
      addLine,
      updateLines,
      // bodegas,
      loteAgregado,
      onQuantity,
      confirmLine,
      validateCant,
      errors,
      minDate,
      maxDate,
      onSearchBatchNumber,
      disableExpDate,
      futureExpDate,
      lotesStore,
      lotesMedicamento,
      lineClose,
      ubicaciones,
      arrayUbicaciones,
      onQuantityDefecto,
      uploadCarta,
      valueDataTemp,
      pdfCartaInput,
      handleChangePdf,
      removeCarta,
      modalNovedades,
      openModalNovedades,
      permisoCartaCompromiso,
      deleteLines,
      temporal
    }
  }
}
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
